<template>
        <div class="filter-actions">
        <b-button :style="{ 'minWidth': '110px' }" :disabled="progress != 100 || isLoad || !variantAttribute" variant="success" @click="$emit('prepareForSave')">{{ $t('modules.budget_request.calculation_forms.table_headers.common.save') }}</b-button>
        </div>
</template>

<script>
export default {
    name: 'FormsSaveBtn',
    props: {
        progress: {
            type: Number,
            required: true,
            defaut: 0
        },
        isLoad: {
            type: Boolean,
            required: true,
            defaut: false
        },
        variantAttribute: {
            type: Boolean,
            required: true,
            defaut: false
        },
    },
}
</script>